import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: [],
  },

  reducers: {
    reset: (state) => {
      state.cart = [];
    },
    addToCart: (state, action) => {
      const itemInCart = state.cart.find(
        (items) => items.livrare === action.payload[3]
      );
      if (itemInCart) {
        if (itemInCart.livrare === action.payload[3])
          itemInCart.quantity += action.payload[0];
        else {
          state.cart.push({
            ...action.payload[2],
            quantity: action.payload[0],
            livrare: action.payload[3],
            ziLivrare: action.payload[4],
          });
        }
      } else {
        state.cart.push({
          ...action.payload[2],
          quantity: action.payload[0],
          livrare: action.payload[3],
          ziLivrare: action.payload[4],
        });
      }
    },
    incrementQuantity: (state, action) => {
      const item = state.cart.find((item) => item.id === action.payload);
      item.quantity++;
    },
    decrementQuantity: (state, action) => {
      const item = state.cart.find((item) => item.id === action.payload);
      if (item.quantity === 1) {
        item.quantity = 1;
      } else {
        item.quantity--;
      }
    },
    removeItem: (state, action) => {
      const removeItem = state.cart.filter(
        (item) => item.livrare !== action.payload
      );
      state.cart = removeItem;
    },
    replaceQuantity: (state, action) => {
      const item = state.cart.find(
        (item) => item.livrare === action.payload[1]
      );
      item.quantity = action.payload[0];
    },
  },
});

export const cartReducer = cartSlice.reducer;
export const {
  addToCart,
  addToCart2,
  addLivrare,
  incrementQuantity,
  decrementQuantity,
  removeItem,
  replaceQuantity,
  reset,
} = cartSlice.actions;
