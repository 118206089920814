import "./styles.css";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero.jsx";
import BodySection1 from "./components/BodySection1";
import DespreNoi from "./components/DespreNoi";
import Footer from "./components/Footer";
import { getToken } from "./helpers";
import {
  Route,
  Routes,
  Outlet,
  Link,
  Navigate,
  useLocation,
} from "react-router-dom";
import ProdusPage from "./components/ProdusPage";
import Signup from "./components/Signin";
import Signin from "./components/Signup";
import Profil from "./components/Profil";
import Cart from "./components/Cart";
import Multumim from "./components/Multumim";
import { useEffect } from "react";
import { WhatsAppWidget } from "react-whatsapp-widget";
import Contact from "./components/Contact";
import ValueItems from "./components/ValueItems";
import DespreNoiSection from "./components/DespreNoiSection";
import Blog from "./components/Blog";
import BlogPage from "./components/BlogPage";
import Feedback from "./components/Feedback";
import "react-whatsapp-widget/dist/index.css";
import CookieConsent from "react-cookie-consent";

function MainLayout({ isHome }) {
  return (
    <>
      <div className="outer__container">
        <Navbar />
        <div className={isHome ? "" : "outlet"}>
          <Outlet />
        </div>
        <Footer />
      </div>
      <WhatsAppWidget
        phoneNumber="40733623202"
        companyName="Regina Albinelor"
        replyTimeText=""
        Page
        message="Buna ziua! Cu ce va putem ajuta?"
        sendButtonText="Trimite"
        inputPlaceHolder="Scrie un mesaj"
      />
    </>
  );
}

function Home() {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <CookieConsent
        buttonText="Acceptă"
        disableButtonStyles={true}
        buttonClasses="!m-5 !rounded-lg border border-1 px-[1.8rem] py-3 text-lg lg:text-xl font-bold text-[#482627] bg-[#FEA21D]"
      >
        Acest site folosește cookie-uri pentru a vă oferi o experiență de
        navigare mai bună. Prin utilizarea acestui site, sunteți de acord cu
        utilizarea cookie-urilor.{" "}
      </CookieConsent>
      <Hero />
      <ValueItems />
      <BodySection1 />
      <DespreNoiSection />
    </>
  );
}

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout isHome={true} />}>
        <Route index element={<Home />} />
      </Route>
      <Route path="/produse" element={<MainLayout />}>
        <Route index element={<BodySection1 />} />
      </Route>
      <Route path="/blog" element={<MainLayout />}>
        <Route index element={<Blog />} />
      </Route>
      <Route path="/blog/:id" element={<MainLayout />}>
        <Route index element={<BlogPage />} />
      </Route>
      <Route path="/contact" element={<MainLayout />}>
        <Route index element={<Contact />} />
      </Route>
      <Route path="/produse/:id" element={<MainLayout />}>
        <Route index element={<ProdusPage />} />
      </Route>
      <Route path="/login" element={<MainLayout />}>
        <Route index element={<Signup />} />
      </Route>
      <Route path="/register" element={<MainLayout />}>
        <Route index element={<Signin />} />
      </Route>
      <Route path="/despre_noi" element={<MainLayout />}>
        <Route index element={<DespreNoi />} />
      </Route>
      <Route path="/cart" element={<MainLayout />}>
        <Route index element={<Cart />} />
      </Route>
      <Route path="/multumim" element={<MainLayout />}>
        <Route index element={<Multumim />} />
      </Route>
      <Route path="/feedback" element={<MainLayout />}>
        <Route index element={<Feedback />} />
      </Route>
      <Route path="/profil" element={<MainLayout />}>
        <Route
          index
          element={getToken() ? <Profil /> : <Navigate to="/login" />}
        ></Route>
      </Route>
    </Routes>
  );
}
export default App;
