import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const qs = require("qs");

const query = (filtru) =>
  qs.stringify(
    {
      filters: {
        Slug: {
          $eq: filtru,
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

export default function BlogPage() {

  useEffect(() => {
    window.scroll({ top: 0, behavior: "instant" });
  }, []);
  const { id } = useParams();
  const [articol, setArticol] = useState();
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          `https://api.regina-albinelor.ro/api/bloguri?populate=*&${query(id)}`
        )
        .then((data) => {
          setArticol(data.data.data);
        })
        .catch(console.error);
    };
    fetchData();
  }, [id]);
  return (
    <div className="min-h-[90vh] mt-[50px] w-[100vw] py-[5rem] px-5 lg:p-[5rem]">
      {articol && (
        <div>
          <h4 className="text-left text-5xl mb-[2rem]">
            {articol[0].attributes.Titlu}
          </h4>
          <p className="!mb-[3rem] text-3xl">
            {articol[0].attributes?.mini_descriere}
          </p>
          <p className="text-xl whitespace-pre-line">
            {articol[0].attributes.Continut}
          </p>
        </div>
      )}
    </div>
  );
}
