import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../cartSlice";
import CartItem from "./CartItem";
import qs from "qs";
import { useNavigate } from "react-router-dom";
// <div className="cart__form">
//   <h5>Data livrarii</h5>
//   <div className="">
//     <select
//       name="livrare"
//       id="livrare"
//       {...register("livrare", {
//         validate: (value) => value !== "DEFAULT",
//       })}
//       defaultValue={"DEFAULT"}
//       style={errors.livrare && { border: "1px solid red" }}
//     >
//       <option value="DEFAULT" disabled>
//         - Selecteaza data de livrare -
//       </option>
//       {Object.values(miercuri).map((item) => {
//         return (
//           <option>{`Miercuri, ${item.getDate().toString()} ${map.get(
//             item.getMonth()
//           )}`}</option>
//         );
//       })}
//     </select>
//   </div>
// </div>

export default function Cart() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "instant" });
  }, []);

  const navigate = useNavigate();

  const [judet, setJudet] = useState("");
  const [localitate, setLocalitate] = useState([]);
  const [valueOfLocalitate, setValueOfLocalitate] = useState("");
  const [agentii, setAgentii] = useState([]);
  const [valueOfAgentie, setValueOfAgentie] = useState("");
  const [loading, setLoading] = useState(true);

  const templateJudete = [
    { auto: "AB", nume: "Alba" },
    { auto: "AR", nume: "Arad" },
    { auto: "AG", nume: "Arges" },
    { auto: "BC", nume: "Bacau" },
    { auto: "BH", nume: "Bihor" },
    { auto: "BN", nume: "Bistrita-Nasaud" },
    { auto: "BT", nume: "Botosani" },
    { auto: "BR", nume: "Braila" },
    { auto: "BV", nume: "Brasov" },
    { auto: "B", nume: "Bucuresti" },
    { auto: "BZ", nume: "Buzau" },
    { auto: "CL", nume: "Calarasi" },
    { auto: "CS", nume: "Caras-Severin" },
    { auto: "CJ", nume: "Cluj" },
    { auto: "CT", nume: "Constanta" },
    { auto: "CV", nume: "Covasna" },
    { auto: "DB", nume: "Dambovita" },
    { auto: "DJ", nume: "Dolj" },
    { auto: "GL", nume: "Galati" },
    { auto: "GR", nume: "Giurgiu" },
    { auto: "GJ", nume: "Gorj" },
    { auto: "HR", nume: "Harghita" },
    { auto: "HD", nume: "Hunedoara" },
    { auto: "IL", nume: "Ialomita" },
    { auto: "IS", nume: "Iasi" },
    { auto: "IF", nume: "Ilfov" },
    { auto: "MM", nume: "Maramures" },
    { auto: "MH", nume: "Mehedinti" },
    { auto: "MS", nume: "Mures" },
    { auto: "NT", nume: "Neamt" },
    { auto: "OT", nume: "Olt" },
    { auto: "PH", nume: "Prahova" },
    { auto: "SJ", nume: "Salaj" },
    { auto: "SM", nume: "Satu Mare" },
    { auto: "SB", nume: "Sibiu" },
    { auto: "SV", nume: "Suceava" },
    { auto: "TR", nume: "Teleorman" },
    { auto: "TM", nume: "Timis" },
    { auto: "TL", nume: "Tulcea" },
    { auto: "VL", nume: "Valcea" },
    { auto: "VS", nume: "Vaslui" },
    { auto: "VN", nume: "Vrancea" },
  ];

  const dispatch = useDispatch();

  const listaJudete = templateJudete.map((judet) => {
    return <option value={judet.nume}>{judet.nume}</option>;
  });

  // let miercuri = [];
  // function getMiercuri(dayName, excludeToday = false, refDate = new Date()) {
  //   const dayOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].indexOf(
  //     dayName.slice(0, 3).toLowerCase()
  //   );
  //   if (dayOfWeek < 0) return;
  //   refDate.setHours(0, 0, 0, 0);
  //   while (refDate.getFullYear() <= 2023) {
  //     refDate.setDate(
  //       refDate.getDate() +
  //         +!!excludeToday +
  //         (dayOfWeek + 7 - refDate.getDay() - +!!excludeToday)
  //     );
  //     miercuri.push(new Date(+refDate));
  //   }
  // }

  // getMiercuri("Wednesday", true);

  const luni = [
    "Ianuarie",
    "Februarie",
    "Martie",
    "Aprilie",
    "Mai",
    "Iunie",
    "Iulie",
    "August",
    "Septembrie",
    "Octombrie",
    "Noiembrie",
    "Decembrie",
  ];
  const map = new Map();
  for (var i = 0; i < 12; i++) {
    map.set(i, luni.at(i));
  }

  useEffect(() => {
    if (judet) {
      async function getOrase() {
        await axios
          .post(`https://regina-albinelor.ro/orase.php?judet=${judet}`)
          .then((res) => {
            setLocalitate(
              res.data.map((localitate) => {
                return <option value={localitate}>{localitate}</option>;
              })
            );
            setLoading(false);
          });
      }
      getOrase();
    }
  }, [judet]);

  useEffect(() => {
    setLoading(true);
    if (judet) {
      async function getAgentii() {
        await axios
          .post(`https://regina-albinelor.ro/agentii.php?judet=${judet}`)
          .then((res) => {
            setAgentii(
              Object.values(res.data).map((item) => {
                return item ? <option value={item}>{item}</option> : "";
              })
            );
            setLoading(false);
          });
      }
      getAgentii();
    }
  }, [judet]);

  const cart = useSelector((state) => state.persistedReducer.cart);

  const getTotalPrice = () => {
    let total = 0;
    cart.forEach((item) => {
      total += item.attributes.Pret * item.quantity;
    });
    return total;
  };

  var pret = getTotalPrice();

  const onSubmit = (data) => {
    var listaProduse = cart
      .map((item) => {
        return `${item.attributes.Titlu} x ${item.quantity} ( Livrare : ${item.livrare} )`;
      })
      .join(", ")
      .toString();

    var obiect = {
      ...data,
      pret: pret,
    };
    axios
      .post(
        `https://regina-albinelor.ro/sms.php?telefon=${obiect.telefon}&sediul_fan=${obiect.agentie}&detalii_produse=${listaProduse}`
      )
      .then((res) => console.log(res));

    cart.forEach((produs) => {
      axios
        .post("https://api.regina-albinelor.ro/api/comenzis", {
          data: {
            destinatar_nume: obiect.nume_prenume,
            destinatar_telefon: obiect.telefon,
            destinatar_localitatea: obiect.localitate,
            destinatar_judet: obiect.judet,
            destinatar_strada: obiect.agentie,
            ramburs: (produs.attributes.Pret * produs.quantity).toString(),
          },
        })
        .then((res) => {
          axios.post(
            "https://api.regina-albinelor.ro/api/comanda-continuturi",
            {
              data: {
                produse_x_cantitate: `${produs.attributes.Titlu} x ${produs.quantity}`,
                pret: produs.attributes.Pret * produs.quantity,
                judet: obiect.judet,
                localitate: obiect.localitate,
                agentie: obiect.agentie,
                nume_prenume: obiect.nume_prenume,
                telefon: obiect.telefon,
                data_livrare: produs.livrare,
                comenzi: {
                  id: res.data,
                },
              },
            }
          );
        })
        .catch((err) => console.log(err));
    });

    cart.forEach((produs) => {
      axios
        .get(
          `https://api.regina-albinelor.ro/api/produse/${produs.id}?populate=*`
        )
        .then((res) => {
          var lista = res.data.data.attributes.saptamana.map((saptamana) => {
            return {
              ...saptamana,
              stoc:
                produs.ziLivrare.data === saptamana.data
                  ? saptamana.stoc - produs.quantity
                  : saptamana.stoc,
            };
          });

          axios.put(`https://api.regina-albinelor.ro/api/produse/${produs.id}?populate=*`, {
            data: {
              saptamana : lista
            }
          })
        });
    });

    dispatch(reset(cart));
    navigate("/multumim", { replace: true });
  };

  return cart.length > 0 ? (
    <div className="cart my-[5rem] p-5 flex flex-col lg:flex-row !w-full ">
      <div className=" cart__items lg:basis-4/6">
        <h6>Cos</h6>
        {cart.map((item) => {
          return <CartItem item={item} />;
        })}
        <div className="total">
          <span>Total : </span>
          <span>
            <b>{pret} Lei</b> (+ aprox. 25 lei transport)
          </span>
        </div>
      </div>
      <div className="form__div__cart lg:basis-2/6">
        <h6>Plaseaza comanda</h6>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h5>Detalii cumparator</h5>
          <input
            {...register("nume_prenume", { required: true })}
            className="border border-1"
            style={errors.nume_prenume && { border: "1px solid red" }}
            placeholder="Nume si prenume"
          ></input>
          <input
            {...register("telefon", {
              required: true,
              pattern: {
                value:
                  /^(\+4|)?(07[2-9]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$/gim,
                message: "Va rugam introduceti un nr de tel ca lumea",
              },
            })}
            style={errors.telefon && { border: "1px solid red" }}
            placeholder="Telefon"
          ></input>
          <input
            {...register("email")}
            placeholder="Email ( nu e obligatoriu )"
          ></input>
          <div className="cart__form">
            <h5>Punct de ridicare ( Sediu Fan Courier )</h5>
            <div className="col-md-6">
              <select
                {...register("judet", {
                  required: true,
                  validate: (value) => value !== "DEFAULT",
                })}
                style={errors.telefon && { border: "1px solid red" }}
                name="judet"
                id="judet"
                onChange={(judet) => setJudet(judet.target.value)}
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  - Alege un judet -
                </option>
                {listaJudete}
              </select>
            </div>
            <div className="col-md-6">
              <select
                name="localitate"
                {...register("localitate", {
                  required: true,
                  validate: (value) => value !== "DEFAULT",
                })}
                style={errors.telefon && { border: "1px solid red" }}
                id="localitate"
                defaultValue={"DEFAULT"}
                onChange={(localitate) =>
                  localitate !== "DEFAULT"
                    ? setValueOfLocalitate(localitate.target.value)
                    : ""
                }
              >
                <option value="DEFAULT" disabled>
                  - Selecteaza Localitate -
                </option>
                {localitate}
              </select>
            </div>
            <div className="col">
              <select
                name="agentie"
                id="agentie"
                {...register("agentie", {
                  validate: (value) => value !== "DEFAULT",
                })}
                defaultValue={"DEFAULT"}
                style={errors.agentie && { border: "1px solid red" }}
                onChange={(agentie) => setValueOfAgentie(agentie.target.value)}
              >
                <option value="DEFAULT" disabled>
                  - Selecteaza sediu Fan Courier -
                </option>
                {agentii}
              </select>
            </div>
          </div>

          <button type="submit">Plaseaza Comanda</button>
        </form>
      </div>
    </div>
  ) : (
    <div className="cart__gol">
      <h1>Ne pare rău, dar coșul dvs. este gol</h1>
    </div>
  );
}
