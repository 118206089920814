import logo from "./logo.png";
import Card from "./Card";
import cover from "./coverImage3.jpeg";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import { Link } from "react-router-dom";
// <div classname="absolute h-[100px] w-full bottom-0">
//   <div classname="arrow"></div>
// </div>
export default function Hero() {
  return (
    <div className="h-[95vh] lg:h-[90vh] bg-[#e7e7e7] relative flex lg:flex-row mt-[50px] lg:mt-[100px] lg:text-left">
      <div className="w-full lg:w-1/2 flex justify-center items-center">
        <div className="mx-3 lg:!mx-[2rem] xl:!mx-[5rem] text-center lg:!text-left ">
          <h1 className="text-5xl xxs:!text-4xl md:!text-6xl lg:!text-7xl mt-[100px] lg:mt-0 mb-[2rem] font-black text-[#482627]">
            MATERIAL GENETIC SELECȚIONAT
          </h1>
          <p className="text-xl sm:text-2xl text-[#717170] mb-[3rem] font-semilight">
            Înțelegem perfect importanța unei{" "}
            <span className="font-bold">mătci de calitate</span> într-o familie
            sănătoasă, de aceea comercializăm doar material biologic performant.
            Mătcile noastre sunt atent selecționate și crescute cu scopul de a
            asigura o producție mare de miere și o vitalitate crescută a
            familiilor de albine.
          </p>
          <a href="#body__section__1">
            <button className="mb-[3rem] lg:m-0 px-[1.8rem] py-3 text-lg lg:text-xl font-bold text-[#482627] bg-[#FEA21D]">
              CUMPĂRĂ
            </button>
          </a>
        </div>
      </div>
      <div className="hidden lg:flex w-full lg:w-1/2 my-[80px] lg:m-0 items-center justify-center">
        <img
          src={cover}
          className="object-cover xxs:max-w-[250px] max-w-[300px] lg:max-w-full "
        ></img>
      </div>
    </div>
  );
}
