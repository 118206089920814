import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { API } from "../constant";
import { setToken } from "../helpers";
import { useForm } from "react-hook-form";

export default function Signup() {
  const { register, handleSubmit, watch } = useForm();

  const navigate = useNavigate();
  const { setUser } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/auth/local/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });


      const data = await response.json();
      if (data?.error) {
        throw data?.error;
      } else {
        // set the token
        setToken(data.jwt);

        // set the user
        setUser(data.user);

        //message.success(`Welcome to Social Cards ${data.user.username}!`);
        navigate("/profil", { replace: true });
      }
    } catch (error) {
      console.error(error);
      setError(error?.message ?? "Ceva nu-i ok");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="form__container">
      <form onSubmit={handleSubmit(onFinish)}>
        <h1>Fa-ti un cont</h1>
        <div className="form__div">
          <input {...register("username")} placeholder="Nume"></input>
          <input {...register("email")} placeholder="Introduceti email"></input>
          <input
            {...register("password")}
            placeholder="Introduceti parola"
            type="password"
          ></input>
          <input
            {...register("confirm_password", {
              required: true,
              validate: (val) => {
                if (watch("password") !== val) {
                  return "Your passwords do no match";
                }
              },
            })}
            placeholder="Confirmati parola"
            type="password"
          ></input>
        </div>
        <div className="buttons">
          <button type="submit">Creeaza cont {isLoading && "loading"}</button>
          <button className="btn__main">
            <Link to="/login">Ai deja cont?</Link>
          </button>
        </div>
      </form>
    </div>
  );
}
