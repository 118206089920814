import {useEffect} from "react";
import pic1 from "./0.jpg";
import pic2 from "./1.jpg";
import pic3 from "./cover2.jpg";

export default function DespreNoi() {
  useEffect(() => {
    window.scroll({top : 0, behavior: "instant"});
  }, []);
  return (
    <div id="body__section__2">
      <h4 className="text-left after:mx-0 text-[#231F1E] text-5xl">Despre noi</h4>
      <div className=" md:w-3/4 mb-[150px]">
        <p className="text-4xl leading-snug text-left md:text-7xl  break-normal  mb-[50px]">
          Suntem o echipă{" "}
          <span className="italic font-medium text-[#231F1E]"> dinamică</span>{" "}
          formată din oameni pasionați și dornici să exploreze limitele
          apiculturii.
        </p>
        <p className="md:text-3xl text-xl text-left ">
          Iubirea față de minunatele albine ne-a adus împreună și am creat o
          fermă apicolă, al cărei scop principal este acela de a crea material
          genetic selecționat. Aspirăm cu fiecare pas spre perfecțiune învățând
          din greșeli și experimentând soluții netestate cu dorința de a crește
          și de a împărtăși cu apicultorii regine de albine de calitate, regine
          capabile să ocupe locurile fruntașe în orice stupină.
        </p>
      </div>
      <div className="flex lg:flex-row flex-col lg:items-center xl:mb-[20px] mb-[50px] ">
        <div>
          <h6 className="mb-4">Viziune</h6>
          <p className="max-w-[100%] text-left text-2xl">
            Dorim să creștem continuu, de aceea colaborăm cu mulți apicultori
            profesioniști, cu ajutorul cărora testăm mătci trase din diferite
            linii, din diferite mătci F0, mătci care în funcție de performanțele
            demonstrate vor intră pe piață sau nu. Importăm anual mătci F0 de la
            diferite ferme consacrate de producție din țări precum Austria,
            Germania cu scopul de a selecționa ce este mai bun și de a
            îmbunătăți continuu materialul genetic.
          </p>
        </div>
      </div>
      <hr></hr>
      <div className="flex lg:flex-row flex-col lg:flex-row-reverse lg:items-center xl:mb-[20px] mb-[50px] xl:mt-[20px] mt-[50px]">
        <div>
          <h6 className="mb-4">Profesionalism</h6>
          <p className="max-w-[100%] text-left text-2xl">
            Mătcile care pleacă de la noi din fermă spre comercializare sunt
            mătci ale căror pontă este verificată minuțios, mătci verificate cu
            toate ramele din nucleu ouate, ele fiind recoltate de pe puietul lor
            capacit. În acest fel reușim să observăm cu o acuratețe crescuta
            calitatea mătcilor.{" "}
          </p>
        </div>
      </div>
      <hr></hr>
      <div className="flex lg:flex-row flex-col lg:items-center xl:mb-[150px] mb-[100px] xl:mt-[20px] mt-[50px]">
        <div>
          <h6 className="mb-4">Calitate</h6>
          <p className="max-w-[100%] text-left text-2xl">
            Un alt aspect la care nu facem rabat de calitate este cel al
            trântorilor, mii de trântori care fac parte din stupi puternici cu
            mătci selecționate în vederea producerii masculilor de calitate,
            stau pregătiți în orice moment să întâmpine tinerele mătci care ies
            la zborul de împerechere.{" "}
          </p>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col lg:items-center xl:mb-[150px] mb-[100px] xl:mt-[20px] mt-[50px] ">
        <div>
          <h6 className="mb-4 text-3xl">Comenzi</h6>
          <p className="max-w-[100%] text-left text-2xl whitespace-pre-wrap">
            Sistemul electronic <b>foarte bine organizat</b> ne permite să nu
            omitem comenzi, să respectăm promisiunile și să ținem legătura cu
            orice apicultor în vederea livrării la timp și fără probleme.
            <br />
            Ne asumăm întreaga responsabilitate cu privire la transport, orice
            problemă apărută în acest sens este suportată exclusiv de noi. În
            cazul mortalității mătcilor la transport, banii se returnează sau se
            retrimite un nou colet cu urgență maximă către clienții afectați.
            <br />
            Că să putem face acest lucru, am decis să trimitem coletele doar în
            sediile Fan Curier din toată țara,{" "}
            <b>nu livrăm deloc la domiciliul clienților.</b>
            <br />
          </p>
        </div>
      </div>
      <p className="text-4xl leading-snug text-center md:text-7xl mb-[50px]">
        Prieteni, va așteptăm ca împreună să ducem apicultura românească la cel
        mai înalt nivel de excelență!
      </p>
    </div>
  );
}
