import {useEffect} from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
export default function Card({
  titlu,
  descriere,
  pret,
  imagine,
  slug,
}) {


  return (
    <div className="card drop-shadow-lg">
      <Link to={`/produse/${slug}`}>
        <img src={imagine} alt="" className="image" />
        <h5>{titlu}</h5>
        <p>{descriere}</p>
        <p className="price !font-semibold">{pret} Lei / buc</p>
      </Link>
    </div>
  );
}
