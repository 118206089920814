import bee from "./valuePhotos/bee.png";
import honey from "./valuePhotos/honey.png";
import keeper from "./valuePhotos/keeper.png";
import matci from "./valuePhotos/matci.png";

function Card({ img, header, paragraph }) {
  return (
    <div className="flex flex-col text-center w-[300px] items-center mx-5 mb-[100px] last:m-0 xl:mx-5 xl:my-0">
      <img src={img} alt="valueImg " className="h-[170px] w-fit mb-3" />
      <h5 className="text-2xl mb-2 text-[#482627]">{header}</h5>
      <p className="text-center text-lg text-[#717170]">{paragraph}</p>
    </div>
  );
}

export default function ValueItems() {
  return (
    <div className="h-content flex flex-wrap py-[6rem] justify-around">
      <Card
        img={matci}
        header="Mătci Carnica F0 100%"
        paragraph="
Producem material genetic selecționat din linii de mătci testate
        "
      />
      <Card
        img={keeper}
        header="Selecționare strictă"
        paragraph="
Comercializăm doar mătci F1 ale căror pontă este verificată minuțios, fiind recoltate doar de pe puiet căpăcit, cu toate ramele din nucleu ouate
        "
      />
      <Card
        img={honey}
        header="Producție mare de miere"
        paragraph="
Unul din criteriile de selecție pe care îl practicăm este instinctul dezvoltat de cules
        "
      />
      <Card
        img={bee}
        header="Transport fără griji"
        paragraph="În cazul problemelor de transport, trimitem alte mătci cu cea mai mare urgență"
      />
    </div>
  );
}
