import {Link} from "react-router-dom";
import img from "./keeper.webp";
export default function DespreNoiSection() {
  return (
    <div className="h-content lg:h-[80vh] flex flex-col lg:flex-row bg-[#F9F8F9]">
      <div class="w-full lg:w-1/2 xl:!pr-0 flex flex-col justify-center px-4 sm:!px-4 lg:!px-[2rem] xl:!px-[5rem]">
        <h4 className="text-left after:mx-0 mt-[5rem] lg:!mt-0 mb-[3rem] text-4xl lg:!text-5xl">Despre noi</h4>
        <div className="text-xl lg:text-2xl text-[#717170] font-semibold">
          <p className="mb-5">
            Produsele noastre izvorăsc din pasiunea pentru natură și{" "}
            <span className="font-extrabold">arta apiculturii</span>. Stupina
            noastră se află într-un sat retras, ascuns intre dealurile din jurul
            Devei.
          </p>
          <p className="font-black mb-5">
            Producem regine capabile să ocupe locurile fruntaşe în orice
            stupină!
          </p>
          <p className="mb-5">
            Țelul nostru este de a îmbunătăți rezultatele apicultorilor români,
            prin producerea de material genetic performant.
          </p>
          <Link to="despre_noi" className="font-light underline">
            Afla mai multe
          </Link>
        </div>
      </div>
      <div className="w-full lg:w-1/2 flex items-center justify-center">
        <img
          src={img}
          className="object-cover max-w-full max-h-full py-[5rem] p-5 sm:max-w-[500px] lg:max-w-full"
          alt="desprenoimg"
        ></img>
      </div>
    </div>
  );
}
