import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { API } from "../constant";
import { useState } from "react";
import { setToken } from "../helpers";
import { useForm } from "react-hook-form";

export default function Signin() {
  const { register, handleSubmit, watch } = useForm();

  const navigate = useNavigate();

  const { setUser } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const value = {
        identifier: values.email,
        password: values.password,
      };
      const response = await fetch(`${API}/auth/local`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });

      const data = await response.json();
      if (data?.error) {
        throw data?.error;
      } else {
        // set the token
        setToken(data.jwt);

        // set the user
        setUser(data.user);

        //message.success(`Welcome back ${data.user.username}!`);

        navigate("/profil", { replace: true });
      }
    } catch (error) {
      console.error(error);
      setError(error?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="form__container">
      <form onSubmit={handleSubmit(onFinish)}>
        <h1>Intra in cont</h1>
        <div className="form__div">
          <input
            {...register("email")}
            placeholder="Introduceti email"
          ></input>
          <input
            {...register("password")}
            placeholder="Introduceti parola"
            type="password"
          ></input>
        </div>
        <div className="buttons">
          <button>Intra in cont</button>
          <button className="btn__main">
            <Link to="/register">Nu ai cont?</Link>
          </button>
        </div>
      </form>
    </div>
  );
}
