import { useEffect } from "react";

export default function Feedback() {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "instant" });
  }, []);

  return (
    <div className="p-5 lg:p-[3rem] text-center min-h-[95vh] w-[100vw] flex flex-col justify-center">
      <h1 className="text-5xl mb-[3rem]">Mulțumim pentru feedback!</h1>
      <p className="text-[1.8rem]">
        Îți mulțumim pentru feedback-ul tău referitor la produsele noastre
        apicole! Apreciem sincer opiniile tale și vom lua în considerare
        sugestiile tale pentru a îmbunătăți calitatea produselor noastre.
      </p>
    </div>
  );
}
