import {useEffect} from "react";

export default function Multumim() {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "instant" });
  }, []);

  return (
    <div className="multumim">
      <h1 className="text-5xl">Mulțumim pentru comandă!</h1>
      <p>
        Vă vom contacta în cel mai scurt timp posibil în legătură cu detaliile
        comenzii.
      </p>
    </div>
  );
}
