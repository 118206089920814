import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function BlogCard({ titlu, descriere, slug }) {
  return (
    <Link to={`/blog/${slug}`} className="mb-[5rem] sm:mr-[3rem]">
      <div className="basis-full flex flex-col justify-between max-w-[388px] lg:w-[400px] h-content sm:h-[250px] p-[1rem] border border-1 shadow-lg">
        <div>
        <h3 className="text-4xl mb-[2rem]">{titlu}</h3>
        <p className="clamp mb-5 text-xl">{descriere}</p>
        </div>
        <span className="text-gray-500 underline ">Vezi mai multe</span>
      </div>
    </Link>
  );
}

export default function Blog() {
  const [produse, setProduse] = useState();
  useEffect(() => {
    window.scroll({ top: 0, behavior: "instant" });
  }, []);

  useEffect(() => {
    async function getData() {
      await axios
        .get("https://api.regina-albinelor.ro/api/bloguri?populate=*")
        .then((response) => setProduse(response.data.data))
        .catch((err) => console.log(err));
    }
    getData();
  }, []);

  return (
    <div className="min-h-[90vh] mt-[50px] w-[100vw] py-[5rem] px-5 lg:p-[5rem]">
      <h4 className="text-left text-5xl">Blog</h4>
      <div className="flex flex-row flex-wrap !mr-0">
        {produse &&
          produse.map((item) => {
            return (
              <BlogCard
                titlu={item.attributes.Titlu}
                descriere={item.attributes.mini_descriere}
                slug={item.attributes.Slug}
              />
            );
          })}
      </div>
    </div>
  );
}
